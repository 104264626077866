.filter-param {
  margin-top: 1rem;
  display: flex;
  align-items: center;
}

.date-select {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}
.search-button {
  margin-left: 1rem;
  margin-top: 1rem;
  padding: 0.5rem 2rem;
  font-size: 1.5rem;
  background-color: #fd6e4e;
  color: white;
  border-radius: 8px;
  cursor: pointer;
}

.search-button:hover {
  background-color: #f95935;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.291);
}

.image-class {
  height: 100px;
  margin-top: 0.5rem;
}

.product-input-sort {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;
}
.input-type2 {
  font-size: 1.2rem;
  padding: 0.5rem;
}

.MuiTable-root input {
  width: 100%;
}

.product-padding {
  padding: 2rem;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}

.table-container {
  overflow-x: auto;
  max-width: 100%;
}

.images-vertical {
  display: flex;
  flex-direction: column;
}
