.login-parent {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(253, 253, 253);
}

.login-body {
  border: 1px solid grey;
  border-radius: 1rem;
  padding: 2rem;
  min-width: 30rem;
  background-color: white;
  box-shadow: 2px 4px 1rem rgba(16, 16, 16, 0.616);
}

.login-title {
  text-align: center;
  font-weight: bold;
  font-size: 4rem;
}

.checkbox {
  display: none;
}

.slider {
  width: 60px;
  height: 30px;
  background-color: lightgray;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
  border: 4px solid transparent;
  transition: 0.3s;
  box-shadow: 0 0 10px 0 rgb(0, 0, 0, 0.25) inset;
  cursor: pointer;
}

.slider::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transform: translateX(-30px);
  border-radius: 20px;
  transition: 0.3s;
  box-shadow: 0 0 10px 3px rgb(0, 0, 0, 0.25);
}

.checkbox:checked ~ .slider::before {
  transform: translateX(30px);
  box-shadow: 0 0 10px 3px rgb(0, 0, 0, 0.25);
}

.checkbox:checked ~ .slider {
  background-color: #fd6e4e;
}

.checkbox:active ~ .slider::before {
  transform: translate(0);
}

.input-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.login-subtitle {
  font-size: 2rem;
  margin: 1rem;
}

.login-input {
  padding: 1.2rem;
  font-size: 1rem;
  background-color: #e7f0fe;
  display: block;
  margin-top: 1rem;
  width: 100%;
  box-sizing: border-box;
  border-radius: 0.8rem;
  border: 1px solid #fd6e4e;
}

.login-input:focus {
  outline: none;
  border: 1px solid #fd6e4e;
  box-shadow: 0 0 8px #fd6e4e;
}

.login-button {
  margin-top: 1rem;
  border-radius: 0.8rem;
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
  background-color: #fd6e4e;
  padding: 1rem;
  text-align: center;
  font-weight: bold;
  width: 100%;
}

.login-button:hover {
  background-color: #e75838;
}
