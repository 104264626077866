.notification {
  padding: 2rem 4rem 2rem 2rem;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}

.notification-title {
  font-weight: bold;
  font-size: 3rem;
}

.input {
  height: 44px;
  width: 100%;
  box-sizing: border-box;
  background-color: #05060f0a;
  border-radius: 0.5rem;
  padding: 0 1rem;
  border: 2px solid transparent;
  font-size: 1rem;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.291);
  margin-top: 0.5rem;
  transition: border-color 0.3s cubic-bezier(0.25, 0.01, 0.25, 1) 0s,
    color 0.3s cubic-bezier(0.25, 0.01, 0.25, 1) 0s,
    background 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
}

.label {
  display: block;
  margin-bottom: 0.3rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: #05060f99;
  transition: color 0.3s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
}

.input:hover,
.input:focus,
.input-group:hover .input {
  outline: none;
  border-color: #05060f;
  box-sizing: border-box;
}

.input-group:hover .label,
.input:focus {
  color: #05060fc2;
}

.input-form {
  margin-top: 2rem;
  max-width: 30rem;
}

.input-distance {
  margin-top: 2rem;
  box-sizing: border-box;
}

.input-type {
  font-size: 1.5rem;
}

.button {
  padding: 1rem 2rem;
  font-size: 1.5rem;
  background-color: #fd6e4e;
  color: white;
  border-radius: 8px;
  width: fit-content;
  margin-top: 1rem;
  cursor: pointer;
}

.button:hover {
  background-color: #f95935;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.291);
}

.button:disabled {
  background-color: #767676;
  box-shadow: none;
}

.notification-users {
  margin-top: 2rem;
  padding: 2rem 0rem;
}

.user-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 2rem;
}

.button-flex {
  display: flex;
}
.reset-button {
  padding: 1rem 2rem;
  font-size: 1.5rem;
  background-color: red;
  color: white;
  border-radius: 8px;
  width: fit-content;
  margin-top: 1rem;
  cursor: pointer;
  margin-left: 1rem;
}

.input-distance-1 {
  margin-top: 1rem;
}

.notification-flex {
  display: flex;
  justify-content: space-between;
}

.noti-margin {
  margin-top: 0.5rem;
}

.noti-colour {
  background-color: #f6f6f8;
}
