.home {
  overflow: hidden;
  height: 100vh;
  width: 100%;
}
.home-design {
  display: flex;
}

.logo {
  display: none;
}

.home-design-menu {
  height: 100vh;
  min-width: 20rem;
  background-color: #fd6e4e;
  padding: 1rem 0rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.swopper-title-align {
  text-align: center;
  margin-bottom: 4rem;
}

.swopper-title {
  font-weight: bold;
  font-size: 2.8rem;
  color: rgb(255, 255, 255);
}
.home-design-item {
  font-size: 1.3rem;
  color: white;
  padding: 1rem 2rem;
  cursor: pointer;
  font-weight: 500;
}

.home-design-item:hover {
  background-color: #fff;
  color: #fe6f4e;
}
.home-design-page {
  height: 100vh;
  overflow-y: auto;
  width: 100%;
  /*  */
}

.overflow-auto {
  /* overflow-y: auto; */
  height: 100%;
  padding: 2rem 2rem 6rem 2rem;
}

.home-design-top {
  width: fit-content;
  padding-bottom: 4rem;
}
.stats {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.active {
  background-color: rgb(0, 0, 0);
  color: #ffffff;
}

.row-pointer {
  cursor: pointer;
}

.product-close {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close {
  cursor: pointer;
}

.circular-bar-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.center {
  text-align: center;
  width: 100%;
}

.logout-pane {
  margin-bottom: 1rem;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logout-text {
  margin-left: 0.5rem;
  color: white;
  font-size: 1.5rem;
}

.logout-icon {
  color: white;
}

.logout-pane:hover {
  background-color: black;
  color: white;
}
.parent-table {
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* Three columns with equal width */
  gap: 20px; /* Space between items */
}

.grid-item {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.grid-item-1 {
  padding: 1rem;
  background-color: black;
  text-align: center;
  color: white;
}

.grid-item-2 {
  padding: 1rem;
  text-align: center;
  color: black;
}

@media only screen and (max-width: 600px) {
  .home-design-menu {
    display: none;
  }

  .logo {
    height: 2rem;
    display: block;
    margin-right: 1rem;
  }

  .stats {
    font-size: 1.5rem;
  }

  .parent-table {
    margin-top: 2rem;
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Three columns with equal width */
    gap: 20px; /* Space between items */
  }
}
